<template>
  <div class="fund-container">
    <div>
      <!-- <h3 class="text-lg font-semibold">Add money to account</h3> -->

      <div class="grid grid-cols-2 justify-items-center w-full">
        <div
          class="w-full text-center relative"
          :class="{ personal: fund === 'card' }"
        >
          <h3
            class="md:text-lg cursor-pointer font-semibold transition-all"
            :class="[fund === 'card' ? 'text-black' : 'text-grey']"
            @click="fund = 'card'"
          >
            Card Top Up
          </h3>
        </div>
        <div
          class="relative w-full text-center"
          :class="{ personal: fund === 'bank' }"
        >
          <h3
            class="md:text-lg cursor-pointer font-semibold transition-all"
            :class="[fund === 'bank' ? 'text-black' : 'text-grey']"
            @click="fund = 'bank'"
          >
            Bank Transfer
          </h3>
        </div>
      </div>
      <Hr />
      <transition name="slideUp" mode="out-in">
        <keep-alive>
          <Bank v-if="fund === 'bank'" @close="$emit('close')" />
          <Card v-if="fund === 'card'" @close="$emit('close')" />
        </keep-alive>
      </transition>
    </div>
  </div>
</template>
<script>
//   import { mapActions } from "vuex";
// import Button from "@/UI/Button";

import Hr from "@/UI/Hr";
// import Card from "./Card";
// import Bank from "./Bank";
export default {
  components: {
    Hr,
    Card: () => import("./Card"),
    Bank: () => import("./Bank"),
  },
  data: () => ({
    fund: "card",
  }),
  methods: {
    submitData() {
      this.$emit("completed");
    },
  },
};
</script>
<style scoped>
@import "~@/assets/styles/animation.css";
.add-card {
  position: absolute;
  top: 3rem;
  right: 0;
}
.personal:after {
  /* content: ""; */
  position: absolute;
  content: "";
  height: 3px;
  border-radius: 2px;
  bottom: -0.65rem;
  margin: 0 auto;
  left: 0;
  /* right: 0; */
  width: 100%;
  background: #4d2ba1;

  /* optional animation */
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.fund-container {
  height: 25rem;
}
</style>
