var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fund-container" }, [
    _c(
      "div",
      [
        _c(
          "div",
          { staticClass: "grid grid-cols-2 justify-items-center w-full" },
          [
            _c(
              "div",
              {
                staticClass: "w-full text-center relative",
                class: { personal: _vm.fund === "card" },
              },
              [
                _c(
                  "h3",
                  {
                    staticClass:
                      "md:text-lg cursor-pointer font-semibold transition-all",
                    class: [_vm.fund === "card" ? "text-black" : "text-grey"],
                    on: {
                      click: function ($event) {
                        _vm.fund = "card"
                      },
                    },
                  },
                  [_vm._v(" Card Top Up ")]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "relative w-full text-center",
                class: { personal: _vm.fund === "bank" },
              },
              [
                _c(
                  "h3",
                  {
                    staticClass:
                      "md:text-lg cursor-pointer font-semibold transition-all",
                    class: [_vm.fund === "bank" ? "text-black" : "text-grey"],
                    on: {
                      click: function ($event) {
                        _vm.fund = "bank"
                      },
                    },
                  },
                  [_vm._v(" Bank Transfer ")]
                ),
              ]
            ),
          ]
        ),
        _c("Hr"),
        _c(
          "transition",
          { attrs: { name: "slideUp", mode: "out-in" } },
          [
            _c(
              "keep-alive",
              [
                _vm.fund === "bank"
                  ? _c("Bank", {
                      on: {
                        close: function ($event) {
                          return _vm.$emit("close")
                        },
                      },
                    })
                  : _vm._e(),
                _vm.fund === "card"
                  ? _c("Card", {
                      on: {
                        close: function ($event) {
                          return _vm.$emit("close")
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }